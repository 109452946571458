import {createApp} from 'vue';
import App from './App.vue';
import PrimeVue from 'primevue/config'; 
import 'primevue/resources/themes/bootstrap4-light-blue/theme.css'; 
import 'primevue/resources/primevue.min.css' 
import 'primeicons/primeicons.css' 
import '/node_modules/primeflex/primeflex.css'
import ButTon from 'primevue/button';

// import OverlayPanel from 'primevue/overlaypanel';
import DiaLog from 'primevue/dialog';

// import GalleriaComponent from 'primevue/galleria' 



import router from './router'

const app = createApp(App);
app.use(PrimeVue);

app.component('DiaLog', DiaLog);
app.component('ButTon', ButTon);

app.use(router);

app.mount('#app')