<template>
  <div v-if="info.data != null">
    <div class=""><h3>{{ info.data.title }}</h3>
      <div class="text-justify mb-2">
        {{ info.data.text }}
      </div>
      <div v-for="link in info.data.linklist" :key="link.id">
        <a :href="link.url" target="_blank"><li>{{ link.title }} <i class="pi pi-link"></i></li></a>
      </div>
      <div class="align-items-center">
        <div v-for="image in info.data.imagelist" :key="image.id" class="image-container cursor-pointer" >
          <img :src="image.url" :alt="image.title" :title="image.title" @click="image.visible = true" >
          <!-- <ButTon label="Show" icon="pi pi-external-link" @click="image.visible = true" /> -->
        <DiaLog v-model:visible="image.visible" modal closable :style="{ width: '95%' }" >
          <img :src="image.url" :alt="image.title" :title="image.title" style="max-width: 100%; max-height: 100%;" >
        </DiaLog>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
export default {
  data () {
    return {
      visible: false,
      millist : null, /* millist, /* null, */
      info : {
        millid : null,
        infoid : null,
        data: null
      },
    }
  },
  beforeMount(){
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    this.info.millid = urlParams.get('millid');
    this.info.infoid = urlParams.get('infoid');
    let sUrl = '/api/';
    if(window.location.host == '192.168.178.15:8080'){
      sUrl = 'http://192.168.178.15/muehlenverein-api/';
    }
    axios.get(sUrl)
      .then(response => {
        console.log(response);
        this.millist = response.data;
        this.info.data = this.millist[this.info.millid].popuplist[this.info.infoid];
    })
  },
  methods: {
        toggle(event) {
            this.$refs.op.toggle(event);
        }
      }
}
</script>
<style>
body{
  font-family: "Oswald", sans-serif;
  padding: 20px;
  text-align: left;
  overflow-x: hidden;
}

a, a:visited{
  text-decoration: none;
  font-weight: bold;
  color: black;
}

.image-container {
      margin: 10px;
      margin-right: 5px;
      margin-left: 5px;
      display: inline-block;
      width: calc(100% - 20px); /* Breite auf 100% abzüglich 20px für die Ränder */
      border-radius: 8px;
      overflow: hidden;
      box-sizing: border-box; /* Fügt padding und border zur Gesamtbreite hinzu */
      
    }

    .image-container img {
      width: 100%;
      height: auto;
      min-width: 200px; /* Mindestens 200 Pixel breit */
      border-radius: 8px;
      object-fit: cover; /* Skaliert das Bild so, dass es den Container füllt, das Seitenverhältnis jedoch beibehält */
    }

    @media screen and (min-width: 600px) {
      .image-container {
        width: calc(50% - 20px);
      }
    }

    @media screen and (min-width: 900px) {
      .image-container {
        width: calc(33.33% - 20px);
      }
    }

    @media screen and (min-width: 1200px) {
      .image-container {
        width: calc(25% - 20px);
      }
    }
</style>
